<template>
  <div>
      <wanalyzer-dataset-analyze :p_datasetView="'yes'"></wanalyzer-dataset-analyze>
  </div>
</template>

<script>
import {
  default as WanalyzerDatasetAnalyze
} from '@/components/widgets/WanalyzerDatasetAnalyze';
import { mapGetters } from 'vuex';
export default {
  name: 'user_dataset_filter_products',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    WanalyzerDatasetAnalyze,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
  },
  watch: {}
}

</script>

